import React, { useState } from "react"
import Base from "./Base"
import Breadcrumbs from "../organisms/breadcrumbs/Breadcrumbs"
import Banner from "../organisms/banner/Banner"
import Paragraph from "../molecules/Paragraph/Paragraph"
import ImageGallery from "../organisms/imageGallery/ImageGallery"
import SubHeading from "../atoms/SubHeading/SubHeading"
import { css } from "@emotion/core"

const StyledParagraph = css`
.has-drop-cap:not(:focus)::first-letter {
	float: left;
	font-size: 8.4em;
	line-height: 0.68;
	font-weight: 100;
	margin: 0.05em 0.1em 1em 0;
	text-transform: uppercase;
	font-style: normal;
}

p.has-drop-cap.has-background {
	overflow: hidden;
}

div {
  margin-top: 1em;
}
`
const Page = ({ pageContext }) => {
  let {
    eventTitle,
    eventDescription,
    eventImages,
    sourceUrl,
    description,
    altText,
  } = pageContext.Event

  return (
    <Base context={pageContext} title={pageContext.title}>
      <Banner
        title={
          pageContext.bannerTitle ? pageContext.bannerTitle : pageContext.title
        }
      />
      <Breadcrumbs />
      <Paragraph originalContent={`${eventDescription}`} customStyling={StyledParagraph} />
      <ImageGallery imageGalleryBlock={{title: '', titleBool: '', gallery: eventImages}} />
    </Base>
  )
}

export default Page
