import React from "react"
import Content from "../../particles/Content"
import { GatsbyImage } from "gatsby-plugin-image";

// logo won't show unless put on a page
// the /test page (holding the logo) fails to build unless this if check is added
const Image = ({ ImageBlock }) => {
  if(ImageBlock) {
    const {align, image} = ImageBlock
    return (
      <Content style={{ textAlign: align, marginBottom: `5rem` }}>
        <GatsbyImage
          image={image.localFile.childImageSharp.gatsbyImageData}
          alt={image.altText}
          style={{ maxHeight: `500px`, marginTop: `3rem` }}
          objectFit="contain" />
      </Content>
    );
  }
  else {
    return null;
  }
}

export default Image
