import { css } from "@emotion/core"
import theme from "../../particles/Theme"

const StyledImageGallery = css`
  .gallery-images {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 768px) {
      .gallery-images {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }
  }
`
export default StyledImageGallery
