import { css } from "@emotion/core"

const StyledParagraph = css`
.has-drop-cap:not(:focus)::first-letter {
	float: left;
	font-size: 8.4em;
	line-height: 0.68;
	font-weight: 100;
	margin: 0.05em 0.1em 0 0;
	text-transform: uppercase;
	font-style: normal;
}

p.has-drop-cap.has-background {
	overflow: hidden;
}
`
export default StyledParagraph