import React from "react"
import Content from "../../particles/Content"
import StyledImageGallery from "./ImageGallery.style"
import Image from "../image/Image"

const ImageGallery = ({ imageGalleryBlock }) => {
  const {title, titleBool, gallery} = imageGalleryBlock;
  return (
    <Content css={StyledImageGallery}>
      <h2 className="gallery-title">{ titleBool && title }</h2>
      <div className="gallery-images">
        {
          gallery.map(image => {
            const ImageBlock = {align:"",image:image};
            return <Image ImageBlock={ImageBlock}></Image>})
        }
      </div>
    </Content>
  )
}

export default ImageGallery;