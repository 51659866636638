import React from "react"
import Content from "../../particles/Content"
import StyledParagraph from "./Paragraph.style"

const Paragraph = (props) => {
  const { originalContent } = props;
  return (
    <Content css={props?.customStyling ? props.customStyling : StyledParagraph}>
      <div dangerouslySetInnerHTML={{ __html: originalContent }} />
    </Content>
  )
}

export default Paragraph
